<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text {{ titles[step] }}

        .container.pt-3
            .row.xstepper-container
                label.step-one.step
                    span.step-num.is-inactive(v-bind:class="{'is-active':this.step === 1, 'is-visited':this.step > 1}") 1
                    span.devider(v-bind:class="{'is-active':this.step === 1, 'is-visited':this.step > 1}")
                label.step-one.step
                    span.step-num.is-inactive(v-bind:class="{'is-active':this.step === 2, 'is-visited':this.step > 2}")  2
                    span.devider(v-bind:class="{'is-active':this.step === 2, 'is-visited':this.step > 2}")
                label.step-one.step
                    span.step-num.is-inactive(v-bind:class="{'is-active':this.step == 3}")  3
                    span.devider(v-bind:class="{'is-active':this.step == 3}")

            .row.purchase-wizard-wrapper

                //.col-12.stepper-container
                    .widget-header
                        //h1.title קניית המוצר
                        v-stepper.mt-4(ref="stepper", :steps='steps', v-model='step')
                .content.p-2
                    step-one(ref="step_one", v-if="step === 1", :item="entity")
                    step-two(ref="step_two",  v-if="step === 2" :item="entity", v-on:method="togglePaymentButton", v-on:paypal-success="nextStep")
                    step-three(ref="step_three",  v-if="step === 3" :item="entity", :order="order")
            .row.footer-wizard
                .d-flex.justify-content-center.gap-bottom
                    b-button.modal-control-buttons.m-2(v-if="step !== 3", variant='secondary', block='', @click='cancelPurchase') בטל
                    b-button.modal-control-buttons.m-2(v-if="step>=2 && step !== 3", variant='secondary', block='', @click='previousStep') חזור שלב
                    b-button.modal-control-buttons.m-2(v-if="step===3", block='', @click='finishPurchase') חזור לעמוד הבית
                    b-button.modal-control-buttons.m-2(variant='warning', block='', @click='nextStep', :disabled="submitButtonDisabled") {{ submit_button_text[step] }}
</template>


<style lang="scss">
    $color-primary: orange;
    $color-bg: #5988d7;
    .footer-wizard {
        .gap-bottom {
            padding-bottom: 6rem;
            width: 30%;
        }
    }

    .xstepper-container {
        display: flex;
        justify-content: space-between;

        .step {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex: 1;

            span.devider {

                width: 100%;
                margin-left: 0.5rem;
                border-bottom: 1px solid #ffffff;
                box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
                &.is-active {
                    border-color: #5988d7;
                    opacity: 0.7;
                }

                &.is-visited {
                    border-color: #5988d7;
                    opacity: 0.7;
                }
            }
            span.step-num {

                transition: opacity 0.7s;
                width: 3.5rem;
                height: 3.5rem;
                display: flex;
                flex-shrink: 0;
                font-size: 1.5rem;
                border-radius: 50%;
                margin-right: 0.5rem;
                color: #ffffff;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: 1px solid #f4f4f4;
                box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);


                &.is-inactive {
                    color: orange;
                    background: #fff;
                    border-color: orange;

                }

                &.is-visited {
                    color: #fff;
                    background: #ffc966;
                    opacity: 0.7;
                }

                &.is-active {
                    color: #fff;
                    background: orange;
                }

                &:hover {
                    transition: opacity 0.7s !important;
                }

            }
        }
    }

    .purchase-wizard-wrapper {



        .stepper-container {

            .divider {
                border-color: $color-bg;
            }

            .v-step {
                .index {
                    color: $color-primary;
                    border-color: $color-primary;
                    background: #fff;
                }
                &.is-active {
                    .index {
                        color: #fff;
                        background: $color-primary;
                    }
                }
                &.is-visited {
                    .index {
                        color: #fff;
                        background: lighten($color-primary, 20);
                    }
                }
            }

        }
    }
</style>


<script lang="js">
    import {VStepper} from 'vue-stepper-component'
    import Vue from 'vue';
    import StepOne from './stepper/stepOne';
    import StepTwo from './stepper/stepTwo';
    import StepThree from './stepper/stepThree';
    export default {
        name: 'purchase',
        components: {VStepper,StepOne, StepTwo, StepThree},
        beforeRouteEnter(to, from, next) {
            if(to.name === 'purchase') {
                Vue.apix.sendHttpRequest('GET', 'item/get-data/', {uuid: localStorage.getItem('ituid')})
                    .then(res => {
                        next(vm => {
                            vm.entity = res
                        })
                    })
                    .catch(err => {
                        window.location.replace('/');
                        return next(false);
                    })
            }
            else console.log(to)
        },
        mounted() {

            // if(!!this.$store.state.ORDER_DETAILS.token) this.step = 3
        },
        data() {
            return {
                order: {
                    method: null,
                    id: null,
                    timestamp: null,

                },
                submitButtonDisabled: false,
                titles: {
                    1: 'שלב 1 - נתוני המוצר',
                    2: 'שלב 2 - תשלום',
                    3: 'שלב 3 - אישור הזמנה'
                },
                submit_button_text: {
                    1: 'הבא',
                    2: 'שלם',
                    3: 'סיים הזמנה'
                },
                busy: false,
                steps: 3,
                step: 1,
                entity: {}
            }
        },
        methods: {
            togglePaymentButton(type){
                this.submitButtonDisabled = type === 2;
            },
            cancelPurchase() {
                if(confirm('האם אתה בטוח שברצונך לבטל את הרכישה?')) {
                    // localStorage.removeItem('ituid');
                    localStorage.removeItem('__paypal_storage__');
                    this.$store.dispatch('removeItem');
                    this.$store.dispatch('removeOrderDetails');
                    this.$store.dispatch('unsetStep');
                    this.$router.push('/')
                }
            },
            finishPurchase(){
                localStorage.removeItem('__paypal_storage__');
                this.$store.dispatch('removeItem');
                this.$store.dispatch('removeOrderDetails');
                this.$store.dispatch('unsetStep');
                this.$router.push('/')
            },
            previousStep(){
                // this.$refs.stepper.previous()
                this.$store.dispatch("setStep", this.$store.state.STEP - 1);
                this.step = 1;
            },
            nextStep(){
                switch (this.step) {
                    case 1: {
                        this.step = 2;
                        break;
                    }

                    case 2: {
                        if (this.$refs.step_two.validate()) {
                            this.$refs.step_two.submit()
                            this.step = 3;
                        }
                        break;
                    }

                    case 3: {
                        break;
                    }
                }
            }
        }
    }
</script>
