<template lang="pug">
    .app-conteiner
        .row.mt-3
            .col-12
                h3 פרטי המוצר
        .row
            .col-sm-12.col-md-6.col-lg-8
                h5 שם המוצר:
                | {{ item.name }}
                .row
                    .col-6
                        h5.mt-5 תיאור המוצר:
                        | {{ item.description }}
                    .col-6
                        h5.mt-5 מאפיינים:
                        | {{ item.specifications }}
                .cost.mt-5(v-b-tooltip.html.hover.right="", :title="`בסיסי: ${item.cost_base}₪,<br> מע\"מ: ${item.cost_pax}₪, <br> חבילה: ${item.cost_shipping}₪`")
                    h5.d-inline מחיר:
                    | {{ ' ' + item.discounted_price }}₪
                h5.mt-5
                    i הערות: {{ item.notes }}
            .col-sm-12.col-md-6.col-lg-4
                .image-container
                    b-img.item-img-p(:src="item.item_img_url")
</template>


<style lang="scss">

    .item-img-p {
        width: 100%
    }
</style>


<script lang="js">
    export default {
        name: 'step-one',
        props: ['item'],
        data() {
            return {

            }
        }
    }
</script>
