<template lang="pug">
    .app-conteiner
        .row.mt-3
            .col-12
                h3 אישור הזמנה
        .row.mt-4
            .col-sm-12.col-md-6.col-lg-8
                h5 שם המוצר:
                | {{ item.name }}
                .row
                    .col-12
                        h5.mt-5 נתוני אישור הזמנה

                        label.d-block
                            b אופן הרכישה:
                            | {{ orderData.method }}
                        label.d-block
                            b מספר אישור סולק:
                            | {{ orderData.token }}
                        label.d-block
                            b מספר הזמנה :
                            | {{ orderData.id }}
                        label.d-block
                            b תאריך זהמנה:
                            | {{ orderData.createdAt }}
                .row.mt-4
                    .col-sm-12
                        i.text-secondary *אחד מנציגינו יצור איתך קשר בימים הקרובים לאישור הזמנה ותואים שליחת המוצר
            .col-sm-12.col-md-6.col-lg-4
                .image-container
                    b-img.item-img-p(:src="item.item_img_url")
</template>


<style lang="scss">

</style>


<script lang="js">
    export default {
        name: 'step-three',
        props: ['item','order'],
        computed: {
            orderData() {
                 let result = this.$store.state.ORDER_DETAILS;
                 return typeof result === 'string' ? JSON.parse(result) : result;
            }
        },
        data() {
            return {
                orderEntity: {

                }
            }
        }
    }
</script>
