<template lang="pug">
    .app-conteiner
        .row.mt-3
            .col-12
                h3 פרטי המוצר
        .row.mt-4
            .col-sm-12.col-md-6.col-lg-8
                h5 שם המוצר:
                | {{ item.name }}
                .row.mt-4
                    .col-12
                        h5 פרטים אישיים:
                .row.mt-2
                    .col-md-4.col-sm-12
                        label דוא"ל
                        input.form-control(v-model="user.email", v-bind:class="{'is-invalid': errors.email.status}", v-debounce:700ms="getUserData")
                    .col-md-4.col-sm-6
                        label שם פרטי
                        input.form-control(v-model="user.first_name", v-bind:class="{'is-invalid': errors.first_name.status}")
                    .col-md-4.col-sm-6
                        label שם משפחה
                        input.form-control(v-model="user.last_name", v-bind:class="{'is-invalid': errors.last_name.status}")

                .row.mt-2
                    .col-md-4.col-sm-12
                        label מספר טלפון
                        input.form-control(@keyup="filterInput($event)", v-model="user.phone", v-bind:class="{'is-invalid': errors.phone.status}")

                    .col-md-5.col-sm-12
                        label כתובת
                        input.form-control(v-model="user.street", v-bind:class="{'is-invalid': errors.street.status}")
                    .col-md-3.col-sm-12
                        label עיר
                        input.form-control(v-model="user.city", v-bind:class="{'is-invalid': errors.city.status}")
                .row.mt-2
                    .col-md-2.col-sm-6
                        label בית
                        input.form-control(type="number",  v-model="user.building_number", v-bind:class="{'is-invalid': errors.building_number.status}")
                    .col-md-2.col-sm-6
                        label דירה
                        input.form-control(type="number", v-model="user.flat_number", v-bind:class="{'is-invalid': errors.flat_number.status}")
                .row.mt-4
                    .col-12
                        h5 בחר צורת תשלום
                .row.mt-4.pb-3
                    .col-4
                        .payment-method-box.clickable(@click="choosePaymentMothod(1)", v-bind:class="{'chosen': currentChosenMethod === 1}")
                            img(src="../../assets/payment/cc.png")
                            small כרטיס אשראי
                    .col-4
                        .payment-method-box.clickable(@click="choosePaymentMothod(2)", v-bind:class="{'chosen': currentChosenMethod === 2}")
                            img(src="../../assets/payment/paypal.svg")
                            small  באמצעות PayPal
                    .col-4
                        .payment-method-box.clickable(@click="choosePaymentMothod(3)", v-bind:class="{'chosen': currentChosenMethod === 3}")
                            img(src="../../assets/payment/wired.png")
                            small העברה בנקאית
                hr(v-if="currentChosenMethod === 1 || currentChosenMethod === 2 || currentChosenMethod === 3")

                // Credit Card
                .row.mt-4(v-if="currentChosenMethod === 1")
                    .col-12
                        h3 עדיין בשלבי פיתוח...
                    //.col-12
                        h5 הזנת פרטי תשלום
                    //.col-sm-12.col-md-6
                        label מספר כרטיס
                        input.form-control(maxlength="16", @keyup="filterInput($event, 'cc')", v-model="paymentMethods.cc.number", v-bind:class="{'is-invalid': errors.number.status}")
                    //.col-sm-4.col-md-2
                        label תוקף
                        datepicker(v-model="paymentMethods.cc.expiration", input-class="form-control", format="yy / MM", minimum-view="month")
                        //input.form-control(@keyup="filterInput($event, 'expiration')", v-model="paymentMethods.cc.expiration", v-bind:class="{'is-invalid': errors.expiration.status}")
                    //.col-sm-4.col-md-2
                        label CVV
                        input.form-control(maxlength="3", v-model="paymentMethods.cc.cvv", v-bind:class="{'is-invalid': errors.cvv.status}")

                // PayPal
                .row.mt-4(v-if="currentChosenMethod === 2")
                    .col-12
                        h5 לתשלום באמצעות PayPal
                        PayPal.mt-3(:amount="`${item.discounted_price}`" currency="ILS" :client="paymentMethods.paypal",
                            env="sandbox", :items="paypalItems", v-on:payment-cancelled="paypalHandler", v-on:payment-completed="paypalHandler")

                // Wired
                .row.mt-4(v-if="currentChosenMethod === 3")
                    .col-12
                        h3 עדיין בשלבי פיתוח...

            .col-sm-12.col-md-6.col-lg-4
                .image-container
                    b-img.item-img-p(:src="item.item_img_url")


</template>


<style lang="scss">
    .payment-method-box {
        display: flex;
        justify-content: center;
        flex-direction: column;


        &.chosen {
            outline: 4px solid orange ;
            outline-offset: 10px;
            width: 100px;
        }

        img {
            width: 85px
        }
    }
</style>


<script lang="js">
    import PayPal from 'vue-paypal-checkout'
    import {PAYPAL} from "../../config";
    import Datepicker         from 'vuejs-datepicker';
    import validator from 'validator';

    export default {
        name: 'step-two',
        props: ['item'],
        directives: {},
        components: {PayPal, Datepicker},
        data() {
            return {
                currentChosenMethod: null,
                user: {
                    phone: '',
                    first_name: '',
                    last_name: '',
                    email: '',
                    street: '',
                    city: '',
                    building_number: '',
                    flat_number: ''
                },
                paymentMethods: {
                    paypal: PAYPAL,
                    cc: {
                        number: '',
                        expiration: '',
                        cvv: ''
                    },
                    wired: {}
                },
                paypalItems: [],
                initErrorObject: null,
                errors: {
                    phone: {status: false, label: 'מספר טלפון'},
                    first_name: {status: false, label: 'שם פרטי'},
                    last_name: {status: false, label: 'שם משפחה'},
                    email: {status: false, label: 'דוא"ל'},
                    street: {status: false, label: 'כתובת'},
                    city: {status: false, label: 'עיר'},
                    building_number: {status: false, label: 'מס\' בית'},
                    flat_number: {status: false, label: 'מס\' דירה'},
                    number: {status: false, label: 'מספר כרטיס'},
                    expiration: {status: false, label: 'תוקף הכרטיס'},
                    cvv: {status: false, label: 'CVV'},
                }
            }
        },
        mounted() {
            this.initErrorObject = JSON.parse(JSON.stringify(this.errors));
            this.paypalItems.push({name: this.item.name, description: this.item.name, quantity: 1, price: this.item.discounted_price, currency: 'ILS'});
        },
        methods: {
            filterInput(e, type = 'any'){
                if(type === 'expiraion')
                    e.target.value = e.target.value.replace(/[^0-9/]+/g, '')
                else if(type === 'cc')
                    e.target.value = e.target.value.replace(/[^0-9]+/g, '')
                else e.target.value = e.target.value.replace(/[^0-9+]+/g, '')
            },
            choosePaymentMothod(opt){
                if(this.validate()) {
                    this.currentChosenMethod = opt;
                    this.$emit('method', opt)
                }
            },
            getUserData(email){
                // this.$apix.sendHttpRequest('GET', 'user/get', {email})
                //     .then(res => {
                //         if(!!res.first_name) {
                //
                //             let loader = this.$loading.show();
                //             setTimeout(() => {
                //                 this.user = {...res, ...{email}};
                //                 loader.hide()
                //             }, 2000)
                //         }
                //     })
                //     .catch(err => {})
            },
            async paypalHandler(event) {

                let pkgUUID;
                try {
                    let res = await this.$apix.sendHttpRequest('POST', 'payment/init', {user: this.user, item: {...{uuid: this.item.uuid}}});
                    if(res.uuid) pkgUUID = res.uuid
                } catch (e) {
                    this.$toast.error('קרתה תקלה - נא ליצור קשר עם צוות תמיכה של האתר')
                }

                console.log(event);

                if(event.state === 'approved') {
                    console.log(pkgUUID);
                    this.$toast.success('התשלום הצליח, מיד תעברו לדף סיום ההזמנה');
                    this.$apix.sendHttpRequest('POST', 'payment/success', {uuid: pkgUUID, paymentMethod: 'PAYPAL', token: event.id, paymentData: JSON.stringify(event)})
                        .then(res => {
                            console.log(res);
                            // if(!!res) this.$router.push('thankyou?type=purchase')
                            if(res) this.$emit('paypal-success', true)
                            this.$store.dispatch('setOrderDetails',{id: res.order_id,token: event.id, method: 'PAYPAL', created_at: res.created_at})
                        })
                        .catch(err => {
                            console.error('There was an issue with the request. Error: ', err);
                            this.$notify.error('קרתה תקלה')
                        });
                }
                else if(!!event.cancelUrl) {
                    this.$toast.warning('התשלום בוטל על ידי במשתמש');
                    this.$apix.sendHttpRequest('POST', 'payment/change-status', {uuid: pkgUUID, status: 'CANCELED'})
                        .then(res => {

                        })
                        .catch(err => {
                            console.error('There was an issue with the request. Error: ', err);
                            this.$notify.error('קרתה תקלה')
                        });
                }
                else {
                    this.$toast.error('התשלום כשל, נא לנסות שנית')
                }
            },
            validate() {
                this.errors = JSON.parse(JSON.stringify(this.initErrorObject));
                let error = false;

                for(let i in Object.keys(this.user)) {
                    if(this.user[Object.keys(this.user)[i]] === '') {
                        error = true;
                        this.errors[Object.keys(this.user)[i]].status = true;
                        this.$toast.error(` שדה ${"'" + this.errors[Object.keys(this.user)[i]].label + "'"} הוא שדה חובה! `)
                    } else if(Object.keys(this.user)[i] === 'phone' && !validator.isMobilePhone(`${Object.values(this.user)[i]}`, 'he-IL')) {
                        this.$toast.error('מספר טלפון לא תקין');
                        this.errors.phone.status = true;
                        error = true;
                    } else if(Object.keys(this.user)[i] === 'email' && !validator.isEmail(Object.values(this.user)[i])) {
                        this.$toast.error('כתובת דוא"ל לא תקינה');
                        this.errors.email.status = true;
                        error = true;

                    }
                }

                for(let i in Object.keys(this.paymentMethods.cc)) {
                    if(this.user[Object.keys(this.paymentMethods.cc)[i]] === '') {
                        error = true;
                        this.errors[Object.keys(this.paymentMethods.cc)[i]].status = true;
                        this.$toast.error(` שדה ${"'" + this.errors[Object.keys(this.paymentMethods.cc)[i]].label + "'"} הוא שדה חובה! `)
                    }
                }

                return !error;
            },
            submit() {

            }
        }
    }
</script>
